import React, { useEffect, useState } from 'react'
import './messagesPop.css'
import { message,Spin } from 'antd';
import { createMyMessages } from '../../../../services/userApiCalls';

const MessagePOP = ({setIsModalOpen,getMessageList,setUpdateData,updateData}) => {
      const [loading, setLoading] = useState(false);
      const [types, setTypes] = useState([]);
      const [title,setTitle] = useState("");
      const [variants, setVariants] = useState(['']);
      const addVariant = () => {
        setVariants([...variants, '']);
    };
    
    const handleVariantChange = (index, value) => {
        const updatedVariants = [...variants];
        updatedVariants[index] = value;
        setVariants(updatedVariants);
    };


      const handleTypes = (type)=>{
         if(types.includes(type)){
            const newTypes = types.filter(t=>t!==type)
            setTypes(newTypes)
         }else{
           setTypes([...types, type])
         }
      }

     const handleSubmit = async()=>{
      
        const varientData = variants?.filter(v=>v.trim()!=="")
        if (!varientData?.length) {
          message.error({
                    content: " Atleast 1 varient is required",
                    style: {
                      position: 'absolute',
                      right: '0px',
                      top: '0px',
                    }
                  })

          return
        }

        if (!types?.length) {
          message.error({
                    content: " Atleast 1 Visibility type is required",
                    style: {
                      position: 'absolute',
                      right: '0px',
                      top: '0px',
                    }
                  })

          return
        }

        if (!title.trim()) {
          message.error({
            content: "Message Name is required",
            style: {
              position: 'absolute',
              right: '0px',
              top: '0px',
            }
          })

  return
        }

        const data = {
          "type": updateData.id? "update": "create",
          "name": title,
          "message_id":updateData?.id || null,
          "visibility": types,
          "variants": variants
        }

          try {
                  const resp =await createMyMessages(data)
                  if (resp.status === 200) {
                    getMessageList()
                    setIsModalOpen(false)
                    clearStates()
                    setUpdateData({})
                    
                  
                  }else{
                    message.error({
                      content: resp?.response?.data?.message,
                      style: {
                        position: 'absolute',
                        right: '0px',
                        top: '0px',
                      }
                    })}
                  
     }catch (err) {}
     
    }
  
    const clearStates = () => {
     setVariants([''])
     setTitle('')
     setTypes([])
  };

  useEffect(()=>{
     if(updateData?.id){
      setTitle(updateData.name)
      setVariants(()=>{
        const varientdata= []
        updateData?.variants?.forEach((variant)=>{
          varientdata.push(variant.name)
        })
        return varientdata
      })
      setTypes(updateData.visibility)
     }

  },[updateData])

  onclose = () => {
    setIsModalOpen(false)
    clearStates()
    setUpdateData({})
  }

  return (
    <div class="dc-msg-popup">
		<div class="dc-msg-popup-inner dc-scroll">
    {loading ? ( // Show loader if loading is true
                  <div className="loader-container">
                    <Spin size="large" />
                  </div>
                ):""}
			<div class="dc-msg-popup-content">
				<div class="a-msg-close-btn">
					<button class="version-pop-close-btn" onClick={()=> onclose()}>
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M11 1L1 11M1 1L11 11" stroke="#949494" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</button>
				</div>
				<div class="dc-create-msg-main dc-msg-step-03">
					<div class="dc-create-msg-inner">
						<span class="dc-main-title">{updateData?.id ? "Update Message":"Create new message"}</span>
						<div class="dc-create-msg-top-chip">
							<input type="text" name="name" maxlength="50" placeholder='Message name' value={title} onChange={(e)=>setTitle(e.target.value)}/>
						</div>
						<div class="dc-create-msg-innerMain">
							<div class="dc-create-leftInnerStart">
								<div class="dc-create-textarea-wraper">
									<div class="dc-create-textarea-scroll dc-scroll">
                  {variants?.map((variant, index) => (
        <div className="dc-create-textarea" key={index}>
            <div className="dc-create-textarea-items">
                <textarea
                    placeholder="Write your text..."
                    value={variant}
                    onChange={(e) => handleVariantChange(index, e.target.value)}
                />
            </div>
        </div>
    ))}
									</div>
								</div>
							</div>
							<div class="dc-create-msg-leftLast">
							    <button class="dc-add-varient-btn" onClick={addVariant} >
							        <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
							            <ellipse cx="18" cy="18.604" rx="18" ry="18.604" fill="url(#paint0_linear_5156_1743)"></ellipse>
							            <path d="M18.96 17.7591L25.1826 17.7591L25.1826 19.5962L18.96 19.5962L18.96 26.0276H17.1825L17.1825 19.5962L10.96 19.5962L10.96 17.7591L17.1825 17.7591L17.1825 11.3278H18.96L18.96 17.7591Z" fill="white"></path>
							            <defs>
							                <linearGradient id="paint0_linear_5156_1743" x1="25" y1="2.58389" x2="8.12209" y2="36.7747" gradientUnits="userSpaceOnUse">
							                    <stop stop-color="#58BFFF"></stop>
							                    <stop offset="1" stop-color="#1D5CFF"></stop>
							                </linearGradient>
							            </defs>
							        </svg> 
							    Add Varient </button>
							</div>
						</div>
					</div>
				</div>
				<div class="dc-create-btn">
				    <div class="nw-msg-footer">
				        <div class="nw-msg-textIcon">
				            <span>Visibility</span>
				            <div class="nw-msg-dropbox">
				                <a class="nw-msg-dropIcon">
				                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.41667 11.0927L6.37933 7H4.95833V5.83333H7.7595L7.7945 9.926H9.1V11.0927H6.41667ZM6.125 3.857C6.11665 3.61002 6.20305 3.36919 6.3665 3.18383C6.52672 3.00494 6.75617 2.91589 7.05483 2.91667C7.3535 2.91667 7.5845 3.00611 7.74783 3.185C7.91117 3.36311 7.99244 3.58711 7.99167 3.857C7.99167 4.123 7.91 4.34389 7.74667 4.51967C7.58333 4.69544 7.35272 4.78333 7.05483 4.78333C6.75694 4.78333 6.5275 4.69544 6.3665 4.51967C6.20242 4.33887 6.11573 4.10097 6.125 3.857ZM7 12.8333C8.5471 12.8333 10.0308 12.2188 11.1248 11.1248C12.2188 10.0308 12.8333 8.5471 12.8333 7C12.8333 5.4529 12.2188 3.96917 11.1248 2.87521C10.0308 1.78125 8.5471 1.16667 7 1.16667C5.4529 1.16667 3.96917 1.78125 2.87521 2.87521C1.78125 3.96917 1.16667 5.4529 1.16667 7C1.16667 8.5471 1.78125 10.0308 2.87521 11.1248C3.96917 12.2188 5.4529 12.8333 7 12.8333ZM7 14C6.08075 14 5.17049 13.8189 4.32122 13.4672C3.47194 13.1154 2.70026 12.5998 2.05025 11.9497C1.40024 11.2997 0.884626 10.5281 0.532843 9.67878C0.18106 8.8295 0 7.91925 0 7C0 6.08075 0.18106 5.17049 0.532843 4.32122C0.884626 3.47194 1.40024 2.70026 2.05025 2.05025C2.70026 1.40024 3.47194 0.884626 4.32122 0.532843C5.17049 0.18106 6.08075 -1.36979e-08 7 0C8.85652 2.76642e-08 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14Z" fill="#170F49"></path>
				                    </svg>
				                </a>
				                <div class="nw-msg-dropCotent">
				                    <span>Tooltip content.</span>
				                </div>
				            </div>
				        </div>
				        <div class="nw-msg-footer-links">
				            
				            <span class={`nw-msg-links ${ types.includes("Group")&& "is_active"}`}   onClick={()=>handleTypes("Group")}>
				                <svg class="nw-msg-stroke" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				                    <path d="M2.5 4.5C2.89782 4.5 3.27936 4.34196 3.56066 4.06066C3.84196 3.77936 4 3.39782 4 3C4 2.60218 3.84196 2.22064 3.56066 1.93934C3.27936 1.65804 2.89782 1.5 2.5 1.5C2.10218 1.5 1.72064 1.65804 1.43934 1.93934C1.15804 2.22064 1 2.60218 1 3C1 3.39782 1.15804 3.77936 1.43934 4.06066C1.72064 4.34196 2.10218 4.5 2.5 4.5ZM3.25 15.75C3.84674 15.75 4.41903 15.5129 4.84099 15.091C5.26295 14.669 5.5 14.0967 5.5 13.5C5.5 12.9033 5.26295 12.331 4.84099 11.909C4.41903 11.4871 3.84674 11.25 3.25 11.25C2.65326 11.25 2.08097 11.4871 1.65901 11.909C1.23705 12.331 1 12.9033 1 13.5C1 14.0967 1.23705 14.669 1.65901 15.091C2.08097 15.5129 2.65326 15.75 3.25 15.75ZM13.75 16.5C14.3467 16.5 14.919 16.2629 15.341 15.841C15.7629 15.419 16 14.8467 16 14.25C16 13.6533 15.7629 13.081 15.341 12.659C14.919 12.2371 14.3467 12 13.75 12C13.1533 12 12.581 12.2371 12.159 12.659C11.7371 13.081 11.5 13.6533 11.5 14.25C11.5 14.8467 11.7371 15.419 12.159 15.841C12.581 16.2629 13.1533 16.5 13.75 16.5ZM7.75 10.5C8.54565 10.5 9.30871 10.1839 9.87132 9.62132C10.4339 9.05871 10.75 8.29565 10.75 7.5C10.75 6.70435 10.4339 5.94129 9.87132 5.37868C9.30871 4.81607 8.54565 4.5 7.75 4.5C6.95435 4.5 6.19129 4.81607 5.62868 5.37868C5.06607 5.94129 4.75 6.70435 4.75 7.5C4.75 8.29565 5.06607 9.05871 5.62868 9.62132C6.19129 10.1839 6.95435 10.5 7.75 10.5ZM12.25 4.5C12.6478 4.5 13.0294 4.34196 13.3107 4.06066C13.592 3.77936 13.75 3.39782 13.75 3C13.75 2.60218 13.592 2.22064 13.3107 1.93934C13.0294 1.65804 12.6478 1.5 12.25 1.5C11.8522 1.5 11.4706 1.65804 11.1893 1.93934C10.908 2.22064 10.75 2.60218 10.75 3C10.75 3.39782 10.908 3.77936 11.1893 4.06066C11.4706 4.34196 11.8522 4.5 12.25 4.5Z" stroke="#4612dd" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
				                    <path d="M3.62549 4.125L5.12549 5.625M10.7505 4.5L10.0005 5.25M12.2505 12.5625L10.0005 9.75M4.75049 11.625L6.25049 10.125" stroke="#4612dd" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
				                </svg>Group</span>
				            <span class={`nw-msg-links ${ types.includes("Post")&& "is_active"}`} onClick={()=>handleTypes("Post")}>
				                <svg class="nw-msg-stroke" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				                    <path d="M2.5 4.5C2.89782 4.5 3.27936 4.34196 3.56066 4.06066C3.84196 3.77936 4 3.39782 4 3C4 2.60218 3.84196 2.22064 3.56066 1.93934C3.27936 1.65804 2.89782 1.5 2.5 1.5C2.10218 1.5 1.72064 1.65804 1.43934 1.93934C1.15804 2.22064 1 2.60218 1 3C1 3.39782 1.15804 3.77936 1.43934 4.06066C1.72064 4.34196 2.10218 4.5 2.5 4.5ZM3.25 15.75C3.84674 15.75 4.41903 15.5129 4.84099 15.091C5.26295 14.669 5.5 14.0967 5.5 13.5C5.5 12.9033 5.26295 12.331 4.84099 11.909C4.41903 11.4871 3.84674 11.25 3.25 11.25C2.65326 11.25 2.08097 11.4871 1.65901 11.909C1.23705 12.331 1 12.9033 1 13.5C1 14.0967 1.23705 14.669 1.65901 15.091C2.08097 15.5129 2.65326 15.75 3.25 15.75ZM13.75 16.5C14.3467 16.5 14.919 16.2629 15.341 15.841C15.7629 15.419 16 14.8467 16 14.25C16 13.6533 15.7629 13.081 15.341 12.659C14.919 12.2371 14.3467 12 13.75 12C13.1533 12 12.581 12.2371 12.159 12.659C11.7371 13.081 11.5 13.6533 11.5 14.25C11.5 14.8467 11.7371 15.419 12.159 15.841C12.581 16.2629 13.1533 16.5 13.75 16.5ZM7.75 10.5C8.54565 10.5 9.30871 10.1839 9.87132 9.62132C10.4339 9.05871 10.75 8.29565 10.75 7.5C10.75 6.70435 10.4339 5.94129 9.87132 5.37868C9.30871 4.81607 8.54565 4.5 7.75 4.5C6.95435 4.5 6.19129 4.81607 5.62868 5.37868C5.06607 5.94129 4.75 6.70435 4.75 7.5C4.75 8.29565 5.06607 9.05871 5.62868 9.62132C6.19129 10.1839 6.95435 10.5 7.75 10.5ZM12.25 4.5C12.6478 4.5 13.0294 4.34196 13.3107 4.06066C13.592 3.77936 13.75 3.39782 13.75 3C13.75 2.60218 13.592 2.22064 13.3107 1.93934C13.0294 1.65804 12.6478 1.5 12.25 1.5C11.8522 1.5 11.4706 1.65804 11.1893 1.93934C10.908 2.22064 10.75 2.60218 10.75 3C10.75 3.39782 10.908 3.77936 11.1893 4.06066C11.4706 4.34196 11.8522 4.5 12.25 4.5Z" stroke="#4612dd" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
				                    <path d="M3.62549 4.125L5.12549 5.625M10.7505 4.5L10.0005 5.25M12.2505 12.5625L10.0005 9.75M4.75049 11.625L6.25049 10.125" stroke="#4612dd" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
				                </svg>Post</span>
				          
				          
				        </div>
				    </div>
			    <div>
			        <button class="dc-lg-btn dc-bg-gray" onClick={()=>handleSubmit()}> {updateData?.id ?  "Update":"Create" }<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			                <path d="M0 5.24242V6.75758H9.84848L5.3346 10.9242L6.5 12L13 6L6.5 0L5.3346 1.07576L9.84848 5.24242H0Z" fill="#fff"></path>
			            </svg>
			        </button>
			    </div>
			</div>
			</div>
		</div>
	</div>
  )
}

export default MessagePOP