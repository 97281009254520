import React from 'react'
import { Input ,Typography} from "antd";

const MembersComp = ({ name ,setName ,email , setEmail,proLink,setProLink}) => {
 
    const {Text } = Typography;

 
  return (
<div>
<div style={{ marginBottom: 24 }}>
          <label>
            <Text strong>Name:</Text>
            <Input
              placeholder="Enter full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                marginTop: 8,
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #d9d9d9",
              }}
            />
          </label>
        </div>
        <div  style={{ marginBottom: 24 }}>
          <label>
            <Text strong>Email:</Text>
            <Input
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                marginTop: 8,
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #d9d9d9",
              }}
            />
          </label>
        </div>
        <div>
          <label>
            <Text strong>Profile Link:</Text>
            <Input
              placeholder="Enter Profile Link"
              value={proLink}
              onChange={(e) => setProLink(e.target.value)}
              style={{
                marginTop: 8,
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #d9d9d9",
              }}
            />
          </label>
        </div>
</div>
  )
}

export default MembersComp