import React from 'react';
import { Form, Input, Button, Checkbox, Typography, Space, Divider } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './loginPage.css';
import { useNavigate } from 'react-router-dom';
import { message  } from 'antd';
import { userLogin } from '../../../../services/userApiCalls';
import loginImage from "../../../../assets/images/mobPass.jpeg"


const { Title, Text } = Typography;

const LoginPage = () => {
  const navigate =useNavigate()
  const onFinish =async (values) => {
    const params = {
      email: values?.username,
      password: values?.password
    }
    try {
      const response =await userLogin(params)
      if (response.status === 200) {
       const Token = response?.data?.data?.token
       localStorage.setItem("token",`${Token}`);
       localStorage.setItem("role",`user`);
        const expiryDays = 7; // Set the cookie to expire in 7 days
        const expires = new Date();
        expires.setTime(expires.getTime() + expiryDays * 24 * 60 * 60 * 1000);
        document.cookie = `token=${Token}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=Strict`;
      window.location.href ="/"
      }else{
        message.error({
          content: 'Invalid credentials',
          style: {
            position: 'absolute',
            right: '0px',
            top: '0px',
          }
        })
      }
     
      
    } catch (error) {
      
    }
   
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className='form-container-main'>
          <div className='login-image-box'>
            <img src={loginImage} alt="loginImg" width={"100%"}/>
          </div>
          <div className="form-container">
            <Title level={2} className="title">
              Welcome Back User
            </Title>
            <Text type="secondary" className="subtitle">
              Please log in to your account
            </Text>
            <Form
              name="login_form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              className="form"
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please enter your username!' }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please enter your password!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  size="large"
                />
              </Form.Item>
                <Form.Item>
                  <Space className="options">
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                    <span onClick={()=>navigate("/forgotPassword")}  className="forgot-password">
                      Forgot password?
                    </span>
                  </Space>
                </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                  size="large"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>

           
    
          </div>
          </div>
      </div>
    </div>
  );
};

export default LoginPage;
