import React, { useEffect, useState } from 'react'
import "./template.css"
import { createTemplate, createTemplateGroup, getTemplate } from '../../../../services/apiCalls';
import { Table, Button, Modal,Typography } from 'antd';
import { message  } from 'antd';
import AddTemp from '../../../components/admin/templateComp/addTemp';
import AddGroupTemp from '../../../components/admin/templateComp/AddGroupTemp';
const { Title } = Typography;

const Template = () => {
    const [temps, setTemps] = useState([]);
     const [isModalOpen, setIsModalOpen] = useState(false);
     const [isGroupModal, setIsGroupModal] = useState(false);
     const [groupData, setGroupData] = useState(null);
 const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [groupName, setGroupName] = useState("");


   const handleSubmit = async () => {
      if (!name.trim()) {
        message.error("Please enter name!");
        return;
      }

      if (!type.trim()) {
        message.error("Please enter Type!");
        return;
      }
  
  
     
      const params = 
        {
          name: name,
          type: type,
        }
  
      try {
        const resp = await createTemplate(params);
        if (resp.status === 200) {
            allTemplateList();
          handleClose();
        } else {
          message.error({
            content: resp?.response?.data?.message,
            style: {
              position: "absolute",
              right: "0px",
              top: "0px",
            },
          });
        }
      } catch (error) {}
    };

    const handleGroupSubmit = async () => {

        if (!groupName.trim()) {
            message.error("Please enter group name!");
            return;
          }
    
          if (!groupData) {
            message.error("Please Select a template!");
            return;
          }
         
          const params = 
            {
              name: groupName,
              template_id: groupData,
            }
      
          try {
            const resp = await createTemplateGroup(params);
            if (resp.status === 200) {
               
                handleCloseGroup();
            } else {
              message.error({
                content: resp?.response?.data?.message,
                style: {
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                },
              });
            }
          } catch (error) {}
    }

    const allTemplateList = async()=>{
       try {
            const response =  await getTemplate( { "page": "1",
                "limit": "10"
              });
            setTemps(response?.data?.data)
            console.log(response)
          } catch (error) {
            console.error('Error fetching users:', error);
          }
    }

  useEffect(() => {
    allTemplateList()
  }, [])

  const handleClose = () => {
    setIsModalOpen(false);
    setName("");
    setType("");
  };

 const handleCloseGroup = () => {
    setIsGroupModal(false)
    setGroupData(null)
    setGroupName("")
 }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
  
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Actions',
      dataIndex: 'Action',
        render: (text, record) => (
              <span>
                <Button type="primary"  onClick={() => {
                     console.log(record)
          setGroupData(record.id)
          setIsGroupModal(true)
                }}>
                  Add Group
                </Button>
            
              </span>),
    
    }
 
   
  ];
  return (
    <div className='users_page'>
    <div className='users-title'>
      <Title level={1}>Users</Title>
      <Button type="primary" onClick={()=>setIsModalOpen(true)} style={{ marginBottom: 16, marginRight: 16 }}>
        Add Template
      </Button>
    </div>


    <Table
      columns={columns}
      dataSource={temps}
      pagination={{ pageSize: 5 }}
      rowKey="key"
    />

<Modal
        title={
          <div>
            <Title level={4}>Add Template</Title>
          </div>
        }
        open={isModalOpen}
        width={"50%"}
        closable={false}
        style={{ background: "transparent" }}
        bodyStyle={{ padding: "24px 32px" }}
        footer={[
          <Button key="cancel" onClick={() => handleClose()}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleSubmit()}>
            Submit
          </Button>,
        ]}
      >
        <AddTemp
          setName={setName}
          name={name}
          setType={setType}
          type={type}
        />
      </Modal>

      <Modal
        title={
          <div>
            <Title level={4}>Add Group</Title>
          </div>
        }
        open={isGroupModal}
        width={"50%"}
        closable={false}
        onClose={() => handleCloseGroup()}
        style={{ background: "transparent" }}
        bodyStyle={{ padding: "24px 32px" }}
        footer={[
          <Button key="cancel" onClick={() => handleCloseGroup()}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleGroupSubmit()}>
            Submit
          </Button>,
        ]}
      >
        <AddGroupTemp
          setName={setGroupName}
          name={groupName}
         
        />
      </Modal>
 
  </div>
  )
}

export default Template